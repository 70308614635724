import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import {
  Account,
  Address,
  AddressCompleet,
  Cancel,
  CancelCompleet,
  Confirm,
  ConfirmEmail,
  Enroll,
  Events,
  EventsCompleet,
  MyInhouseIndex,
  ParticipationCompleet,
  Payment,
  Picker,
  PrePicker,
  PrivateRoute,
  Profile,
  ProfileWizard,
  ResetPassword,
} from '../components';

const RouteResetPassword = (props: RouteComponentProps) => <ResetPassword />;
const RouteConfirmEmail = (props: RouteComponentProps) => <ConfirmEmail />;

const StudentPage = () => (
  <Router>
    <PrivateRoute path="/student/deelname-annuleren-compleet/" component={CancelCompleet} />
    <PrivateRoute path="/student/deelname-annuleren/" component={Cancel} />
    <PrivateRoute path="/student/deelname-compleet/" component={ParticipationCompleet} />
    <PrivateRoute path="/student/deelname-machtiging/" component={Payment} />
    <PrivateRoute path="/student/deelname-bevestiging/" component={Confirm} />
    <PrivateRoute path="/student/deelname-aanmelden/" component={Enroll} />
    <PrivateRoute path="/student/programma-compleet/" component={EventsCompleet} />
    <PrivateRoute path="/student/programma-adres/" component={Address} />
    <PrivateRoute path="/student/programma-adres-compleet/" component={AddressCompleet} />
    <PrivateRoute path="/student/programma-invullen/" component={Picker} />
    <PrivateRoute path="/student/programma/" component={Events} />
    <PrivateRoute path="/student/inhousedagen/" component={MyInhouseIndex} />
    <PrivateRoute path="/student/voor-programma/" component={PrePicker} />
    <PrivateRoute path="/student/account/" component={Account} />
    <PrivateRoute path="/student/profiel/" component={Profile} />
    <PrivateRoute path="/student/profiel-invullen/*" component={ProfileWizard} />
    <RouteResetPassword path="/student/wachtwoord-resetten/" />
    <RouteConfirmEmail path="/student/email-bevestigen/" />
  </Router>
);

export default StudentPage;
